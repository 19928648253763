













































































































































import { defineComponent, reactive, ref, toRefs, computed, unref, set,onMounted } from '@vue/composition-api'
import { Form, FormItem, Tooltip, Upload } from 'element-ui'
import Editor from '@/components/Editor.vue'
import PreviewProject from '@/components/preview/PreviewProject.vue'
import { uploadHandle, imageTypes } from '@/utils/upload'
import { getProjectApi, updateOrCreateProjectApi } from '@/api/project'
import { leaveConfirm, showError } from '@/utils/common'
import { UpdateOrCreateProjectDto, ProjectStatus } from '@/api/project/model'
import { uploadImage } from '@/api/common'
import ProjectHeader from './components/ProjectHeader.vue'
import { projectTopicOption } from '@/api/project/topic-option'
import { projectIndustryOption } from '@/api/project/industry-option'
import _ from 'lodash'
import { LocaleType } from '@/interface/common'
import { useSettingStore } from '@/pinia/modules/setting'
import { isoToLocal } from '@/utils/time-format'

export default defineComponent({
    components: {
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Upload.name]: Upload,
        [Tooltip.name]: Tooltip,
        Editor,
        ProjectHeader,
        PreviewProject
    },
    setup(props, { root, refs }) {
        const settingStore = useSettingStore()
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const isDisabledForm = ref(false)
        const formRef = ref()
        const formInfo: UpdateOrCreateProjectDto = reactive({
            project_name: '',
            title: '',
            cover: '',
            introduction: <string>root.$i18n.t('projectIntroduce.introductionValue'),
            moderator_cover: '',
            moderator_name: '',
            limit_number: 50,
            number: 50,
            estimated_time: 60 * 15,
            expect_at: null,
            timezone: "Asia/Shanghai",
            expect_date: '',
            expect_time: '',
            topic: '',
            industry: '',
            company: '',
            research_purpose: '',
            language: settingStore.locale
        })
        const formRules = reactive({
            project_name: [{ required: true, message: root.$t('projectIntroduce.projectNamePlaceholder'), trigger: 'blur' }],
            topic: [{ required: true, message: root.$t('projectIntroduce.domainPlaceholder'), trigger: 'change' }],
            industry: [{ required: true, message: root.$t('projectIntroduce.industryPlaceholder'), trigger: 'change' }],
            title: [{ required: true, message: root.$t('projectIntroduce.conversationTitlePlaceholder'), trigger: 'blur' }],
            company: [{ required: true, message: root.$t('projectIntroduce.companyNamePlaceholder'), trigger: 'blur' }],
            research_purpose: [{ required: true, message: root.$t('projectIntroduce.surveyPurposeNamePlaceholder'), trigger: 'blur' }],
            cover: [{ required: true, message: root.$t('projectIntroduce.coverImagePlaceholder'), trigger: 'change' }],
            introduction: [{ required: true, message: root.$t('projectIntroduce.introductionPlaceholder'), trigger: 'change' }],
            moderator_name: [{ required: true, message: root.$t('projectIntroduce.moderatorNamePlaceholder'), trigger: 'blur' }],
            moderator_cover: [{ required: true, message: root.$t('projectIntroduce.moderatorAvatarPlaceholder'), trigger: 'change' }],
            language: [{ required: true, message: root.$t('projectIntroduce.languagePlaceholder'), trigger: 'change' }]
        })
        const formInfoCopy = {}
        const detailObj: any = reactive({})
        const getProject = async () => {
            try {
                const { details, status } = await getProjectApi({
                    uuid: projectUuId.value
                })
                isDisabledForm.value = [ProjectStatus.Published, ProjectStatus.Start, ProjectStatus.End].includes(status)
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
                Object.keys(formInfo).forEach((key) => {
                    formInfo[key] = details[key]
                })
                if(formInfo.expect_at) {
                    const expect_at = isoToLocal(formInfo.expect_at || '', formInfo.timezone)
                    formInfo.expect_time = formInfo.expect_date = expect_at
                }
                Object.assign(formInfoCopy, formInfo)
            } catch (error) {
                showError(error)
            }
        }
        if (projectUuId.value) {
            getProject()
        }

         const localeOptions:any = ref([])

        const buildAndSortLocaleOptions = () => {
            let options = [
                {
                    name: root.$i18n.t('locale.en'),
                    value: LocaleType.en_US
                },
                {
                    name: root.$i18n.t('locale.zh_CN'),
                    value: LocaleType.zh_CN
                },
                {
                    name: root.$i18n.t('locale.zh_TW'),
                    value: LocaleType.zh_TW
                },
                {
                    name: root.$i18n.t('locale.vi_VN'),
                    value: LocaleType.vi_VN
                },
                {
                    name: root.$i18n.t('locale.fr_FR'),
                    value: LocaleType.fr_FR
                },
                {
                    name: root.$i18n.t('locale.es_ES'),
                    value: LocaleType.es_ES
                },
                {
                    name: root.$i18n.t('locale.id_ID'),
                    value: LocaleType.id_ID
                }, {
                    name: root.$i18n.t('locale.tr_TR'),
                    value: LocaleType.tr_TR
                }, {
                    name: root.$i18n.t('locale.th_TH'),
                    value: LocaleType.th_TH
                }, {
                    name: root.$i18n.t('locale.ja_JP'),
                    value: LocaleType.ja_JP
                }, {
                    name: root.$i18n.t('locale.es_MX'),
                    value: LocaleType.es_MX
                }, {
                    name: root.$i18n.t('locale.ms_MY'),
                    value: LocaleType.ms_MY
                },{
                    name: root.$i18n.t('locale.sv_SE'),
                    value: LocaleType.sv_SE
                }
            ];

            // 获取所有名称并排序
           const sortedOptions = options.sort((a:any, b:any) => a.name.localeCompare(b.name));
            
            localeOptions.value = sortedOptions;
        }

        const coverUploadInfo = reactive({
            loading: false,
            before: (file: File) => {
                return uploadHandle(file, 1)
            },
            request: async (value: any) => {
                coverUploadInfo.loading = true
                try {
                    const { url } = await uploadImage({
                        file: value.file
                    })
                    formInfo.cover = url
                } catch (error) {
                    showError(error)
                }
                coverUploadInfo.loading = false
            }
        })

        const moderatorCoverUploadInfo = reactive({
            loading: false,
            before: (file: File) => {
                return uploadHandle(file, 1, 5)
            },
            request: async (value: any) => {
                moderatorCoverUploadInfo.loading = true
                try {
                    const { url } = await uploadImage({
                        file: value.file
                    })
                    formInfo.moderator_cover = url
                } catch (error) {
                    showError(error)
                }
                moderatorCoverUploadInfo.loading = false
            }
        })

        const submitInfo = reactive({
            isSubmitLoad: false,
            isDisabled: computed(() => {
                return !Object.keys(formRules).every((key) => formInfo[key]) || isDisabledForm.value
            }),
            onSubmitFrom: (isNext = false) => {
                formRef.value.validate().then(async () => {
                    submitInfo.isSubmitLoad = true
                    try {
                        const option = { ...formInfo }
                        if (projectUuId.value) {
                            option.project_uuid = projectUuId.value
                        }
                        const uuid = await updateOrCreateProjectApi(option)
                        root.$message.success(root.$t('saveSuccess').toString())
                        if (isNext) {
                            root.$router.push({
                                name: 'ProjectTime',
                                params: {
                                    projectUuId: uuid
                                }
                            })
                        } else {
                            if (!projectUuId.value) {
                                await root.$router.replace({
                                    name: 'ProjectIntroduce',
                                    params: {
                                        projectUuId: uuid
                                    }
                                })
                            }
                            getProject()
                        }
                    } catch (error) {
                        showError(error)
                    }
                    submitInfo.isSubmitLoad = false
                })
            }
        })

        const goNext = async () => {
            console.log(JSON.parse(JSON.stringify(formInfo)), JSON.parse(JSON.stringify(formInfoCopy)))

            try {
                await leaveConfirm(formInfo, formInfoCopy)
                root.$router.push({
                    name: 'ProjectTime'
                })
            } catch (error) {
                console.log(error)
            }
        }
        onMounted(() => {  
            buildAndSortLocaleOptions()
        })
        return {
            isDisabledForm,
            projectUuId,
            formRef,
            formInfo,
            formRules,
            detailObj,
            goNext,
            coverUploadInfo,
            moderatorCoverUploadInfo,
            imageTypes,
            submitInfo,
            projectTopicOption,
            projectIndustryOption,
            localeOptions,
            buildAndSortLocaleOptions
        }
    }
})
